// import axios from "axios";
import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useContext,
} from "react";
import MyContext from "../Component/MyContext";
import LoginApi from "../API/API_SERVISES/Login";
import { useNavigate } from "react-router-dom";
import OTPinfo from "../API/API_SERVISES/OTPinfo";
import Account from "../API/API_SERVISES/Account";
import ReserPasswordPanel from "../Component/MobileSections/ReserPasswordPanel";
import GetOTPPanel from "../Component/MobileSections/GetOTPPanel";
import SignInPanel from "../Component/MobileSections/SignInPanel";
import LoginPanel from "../Component/MobileSections/LoginPanel";
import ForgotPasswordPanel from "../Component/MobileSections/ForgotPasswordPanel";
import Loader from "../Component/Loader";
import Order from "../API/API_SERVISES/Order";
import AddAddress from "../API/API_SERVISES/AddAddress";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import SmsTemplate from "../API/API_SERVISES/SMSTemplate";
import SMS from "../API/API_SERVISES/SMS";

const MyAccount = forwardRef((props, ref) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { setLoginShow, CheckOtpAndSignIn, fetchCartdata, setAddAddresses, setAllottedTimeSlot, AllottedTimeSlot, CompanyDetail, messageWhatsAppFunction } = useContext(MyContext);

  const handleEscapeKey = () => {
    props.close();
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [visibleResendOTP, setvisibleResendOTP] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(true);
  };
  const handleTogglePasswordhide = () => {
    setShowPassword(false);
  };

  const handleTogglePassword1 = () => {
    setShowPassword1(true);
  };
  const handleTogglePasswordhide1 = () => {
    setShowPassword1(false);
  };

  const [allAccountData, setAllAccountData] = useState({})

  useEffect(() => {
    console.log(allAccountData);
  }, [allAccountData])

  const GetAllAccount = async () => {
    const AllAccount = await Account.getAccount()
    setAllAccountData(AllAccount);
  }
  useEffect(() => {
    GetAllAccount()
  }, [])

  const [inputValuesForLogin, setInputValuesForLogin] = useState({
    name_email: "",
    password: "",
  });

  const [inputValuesForOTLogin, setInputValuesForOTLogin] = useState({
    name_email: "",
  });

  const [inputValuesForSignIn, setInputValuesForSignIn] = useState({
    name: "",
    aEmail: "",
    aMobileNo: "",
    password: "",
    reEnterpassword: "",
    namePrefix: "",
    printName: "",
    alias: "",
    accountType: 0,
    parentGrp: 0,
    aState: -1,
    aStation: -1,
    aDefaultSalePerson: -1,
    adob: "",
    aAge: 0,
    adom: "",
    aGender: "",
    aDepartment: 0,
    aDesignation: 0,
    aDrugLicenseNo: "",
    aOpeningBal: 0,
    aOpeningCrDr: "",
    aContactperson: "",
    contactpersonMbl: "",
    aCreditlimit: 0,
    aCreditperiod: 0,
    aAccountCategory: -1,
    aEnableLoyalty: false,
    aLoyaltyCardNo: "",
    referralBy: -1,
    assignUser: -1,
    aAddress: "",
    aEnableEmail: "",
    aPincode: "",
    aLocation: "",
    aDistance: "",
    accountDealerType: "",
    agStINNo: "",
    aitPan: "",
    alstNo: "",
    acstNo: "",
    aAadharNo: "",
    doNotDisturb: "",
    accountDetails: "",
    aDefaultTranspoter: -1,
    openingCrDr: "",
    system_defined: false,
    createdBy: "Self",
    createdOn: "",
    isActive: true,
    accOptFieldValues: [
      {
        optionalFieldName: "",
        optionalFieldValue: "",
        isDeleted: false,
        isActive: true,
      },
    ],
  });

  const [inputValuesForOTP, setInputValuesForOTP] = useState({
    otp: "",
  });

  const [inputValuesForForgotPassword, setInputValuesForForgotPassword] = useState({
    otp: "",
  });

  const handleEnterKey = () => {
    LoginAccount();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "Escape":
          handleEscapeKey();
          break;
        case "Enter":
          handleEnterKey();
          break;
        default:
          break;
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const { loginPanel, signin, signupshow, signupshow1, getOtp, GoToOTP, forgotPaggword, forgot, ResetPassword, ResetPasswordUI, myaccountmodal } =
    useContext(MyContext);

  const [checkOTP, setCheckOtp] = useState(false);

  const resendOTP = () => {
    alert("resend otp")
  }

  const SendOtp = async () => {

    // if (!isValidEmail || inputValuesForSignIn.aEmail === "") {
    //   alert("Please enter valid Email Address");
    //   return false;
    // }
    // else 
    if (!isValidPhone || inputValuesForSignIn.aMobileNo === "" || inputValuesForSignIn.aMobileNo.length < 9) {
      alert("Please enter valid 10 digit Phone No");
      return false;
    }
    if (inputValuesForSignIn.name === "") {
      alert("Please enter valid name");
      return false;
    } if (inputValuesForSignIn.password === "") {
      alert("Please enter valid password");
      return false;
    } if (inputValuesForSignIn.reEnterpassword === "") {
      alert("Please enter valid password");
      return false;
    }
    else {
      setLoading(true)
      if (inputValuesForSignIn.password === inputValuesForSignIn.reEnterpassword) {
        var sendotp = await OTPinfo.SendOtp(inputValuesForSignIn)

        if (sendotp.result === "Account already exists.") {
          setLoading(false)
          alert(sendotp.result)
          signupshow()
          setInputValuesForSignIn("")
        } else {

          messageWhatsAppFunction("for_otp", 0, 0, inputValuesForSignIn.aMobileNo)

          setLoading(false)
          GoToOTP()
          setIsDisabled(true);
          setvisibleResendOTP(true)
          setSecondsLeft(60);
        }
      }
      else {
        alert("Password and Re-enter password does not match");
        setLoading(false);
      }
    }
  }

  const [isDisabled, setIsDisabled] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(0);

  // This function is called when the button is clicked
  const handleClick = () => {
    setIsDisabled(true);  // Disable the button
    setSecondsLeft(60);   // Start countdown from 60 seconds
    SendOtpForForgotPassword()
  };


  useEffect(() => {
    let interval = null;

    if (isDisabled && secondsLeft > 0) {
      // Update the countdown every second
      interval = setInterval(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      // Re-enable the button when countdown reaches zero
      setIsDisabled(false);
    }
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [isDisabled, secondsLeft]);

  const [loading, setLoading] = useState(false);

  const SendOtpForForgotPassword = async () => {
    if (!inputValuesForSignIn.aEmail) {
      alert("Please enter a valid Email/Mobile");
      return false;
    }
    // ******************* Check if account is Exist Or Not ********************
    var checkType = ""
    if (inputValuesForSignIn.aEmail.includes('@')) {
      checkType = "Email"
    }
    else if (/^[\d\s-()+]+$/.test(inputValuesForSignIn.aEmail)) {
      checkType = "MobileNo"
    }

    let requestData = {};

    if (checkType === "Email") {
      requestData = [{
        "fieldName": "email",
        "operatorName": "equal",
        "compareValue": inputValuesForSignIn.aEmail
      }];
    } else if (checkType === "MobileNo") {
      requestData = [{
        "fieldName": "mobileNo",
        "operatorName": "equal",
        "compareValue": inputValuesForSignIn.aEmail
      }];
    }
    const accountData = await Account.getAccountWithFilter(requestData)
    if (accountData.length == 0) {
      alert("Account does not exist, Please Sign up")
      signupshow1();
      return 0;
    }

    if (!isDisabled) {
      try {
        setLoading(true);
        const sendOtpForForgot = await OTPinfo.SendOtpForForgotPassword(inputValuesForSignIn);
        console.log(sendOtpForForgot);
        if (sendOtpForForgot) {

          messageWhatsAppFunction("for_otp", 0, 0, inputValuesForSignIn.aEmail)

          setvisibleResendOTP(true);
          setIsDisabled(true);  // Disable the button
          setSecondsLeft(60);
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        alert("An error occurred while sending OTP. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const VerifyOTP = async () => {
    if (inputValuesForSignIn.aEmail == "" && inputValuesForSignIn.aMobileNo == "") {
      alert("Please enter Email/Mobile for OTP");
      return false
    }
    else if (inputValuesForOTP.otp == "") {
      alert("Please enter Correct OTP");
      return false
    }
    setLoading(true)
    const VerifyOtp = await OTPinfo.VerifyOtp(inputValuesForSignIn, inputValuesForOTP)
    console.log(VerifyOtp)
    if (VerifyOtp === "OTP failed to verify! Incorrect OTP!") {
      alert(VerifyOtp)
      setLoading(false)
    } else {
      ResetPasswordUI()
      setLoading(false)
    }
  }

  const handleInputChangeLogin = (event) => {
    const { name, value } = event.target;

    setInputValuesForLogin((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleInputChangeLoginOT = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValuesForOTLogin((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [isValidEmail, setIsValidEmail] = useState(true); // Initially assuming email is valid
  const [isValidPhone, setIsValidPhone] = useState(true);

  const validateEmail = (email) => {
    const isValid = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);
    setIsValidEmail(isValid);
  };

  const validatePhone = (phone) => {
    // Basic phone number validation (example for 10 digit US phone number)
    const isValid = /^\d{10}$/.test(phone);
    setIsValidPhone(isValid);
  };

  const handleInputChangeSignIn = (event) => {
    const { name, value } = event.target;
    // console.log(`Changing ${name} to ${value}`);
    setInputValuesForSignIn((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name === 'aEmail') {
      validateEmail(value);
    } else if (name === 'aMobileNo') {
      validatePhone(value);
    }
  };

  const subtract30Minutes = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    date.setMinutes(date.getMinutes() - 30);

    const updatedHours = String(date.getHours()).padStart(2, '0');
    const updatedMinutes = String(date.getMinutes()).padStart(2, '0');
    const updatedSeconds = String(date.getSeconds()).padStart(2, '0');

    return `${updatedHours}:${updatedMinutes}:${updatedSeconds}`;
  }

  const fetchAllotedTimeSlot = async (updatedValues, serviceTypeData) => {
    try {
      setLoading(true);
      const deliverySlot = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues, CompanyDetail.isEnableGoogleMap);
      console.log("selectedTimeSlot", deliverySlot);
      let data = []
      if (deliverySlot && deliverySlot.length > 0) {
        data = deliverySlot.filter((data, index, self) =>
          index === self.findIndex((t) => (
            t.id === data.id
          ))
        );
      }
      const serviceTypeId = updatedValues.orderType;
      const serviceData = (serviceTypeData != null && serviceTypeData != undefined && serviceTypeData.length > 0) ? serviceTypeData.filter(item => item.id == serviceTypeId) : []
      const selectedTimeSlot = data.slice(0, serviceData[0]?.noOfTimeSlot);

      if (selectedTimeSlot.length > 0) {
        localStorage.setItem("deliveryTime", selectedTimeSlot[0]?.id)
      }

      console.log("selectedTimeSlot", selectedTimeSlot)
      console.log("selectedTimeSlot", updatedValues.orderType, serviceData[0]?.noOfTimeSlot)

      if (data) {
        setAllottedTimeSlot(selectedTimeSlot);
      } else {
        setAllottedTimeSlot("");
        //alert("No data found");
      }
      setLoading(false)

      var kotDisTargetTime = "00";
      if (selectedTimeSlot.length > 0) {
        kotDisTargetTime = subtract30Minutes(selectedTimeSlot[0].availableFrom);
      }
      // setOrderData((prevValues) => ({
      //   ...prevValues,
      //   deliveryTimeId: data && data[0] && data[0].id !== undefined ? data[0].id : "",
      //   kotDispatchTargetTime: kotDisTargetTime || "00:00:00",
      //   deliveryCharges: selectedTimeSlot[0]?.deliveryCharges,
      // }));

      // //setTableGroup(cartData[0].tableGroupId)
      // setInputValues((prevValues) => ({
      //   ...prevValues,
      //   serviceTypeId: serviceTypeId,
      //   deliveryTimeId: data && data[0] && data[0].id !== undefined ? data[0].id : "",
      //   kotDispatchTargetTime: kotDisTargetTime || "00:00:00",
      //   deliveryCharges: selectedTimeSlot[0]?.deliveryCharges,
      // }));

    } catch (error) {
      setLoading(false)
      console.error("Error fetching time slots:", error);
    }
  }

  const LoginAccount = async () => {
    const Login = await LoginApi.LogInAccount(inputValuesForLogin);
    if (Login?.result) {
      props.close();
      await Order.UpdateClientIdByAccountId(Login.result);
      localStorage.setItem("accountLogin", JSON.stringify(Login.result));
      var addresses = await AddAddress.getAddress(Login.result.d1Id);
      setLoginShow(false);
      setAddAddresses(addresses);

      // ************************** Default Time Slot **********************
      const defaultAdd = addresses.filter(item => item.isDefault == true);
      //alert(defaultAdd[0].distance)

      const serviceType = localStorage.getItem("ServiceType");
      const updatedValues = {
        orderType: serviceType || 751,
        deliveryPointName: 1,
        distance: defaultAdd ? defaultAdd[0]?.distance : 0,
        isCurrentDate: true
      };
      // const data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues, CompanyDetail.isEnableGoogleMap);
      // setAllottedTimeSlot(data);
      const serviceTypeData = await Order.getServiceType();
      fetchAllotedTimeSlot(updatedValues, serviceTypeData)
      // var isFromOrderTaker = localStorage.getItem("OrderTaker");
      // if (isFromOrderTaker !== "Yes") {
      fetchCartdata()
      // }
      alert(`Welcome ${Login.result.name}`);

      const storedArrayString = localStorage.getItem("DefaultAddress");
      if (storedArrayString) {
        try {
          const defaultAddress = JSON.parse(storedArrayString);

          const addressData = {
            name: Login.result.name,
            mobile: Login.result.mobileNo,
            pincode: defaultAddress.pincode,
            state: 0,
            address: defaultAddress.address,
            locality: "",
            city: "",
            latitude: defaultAddress.latitude,
            longitude: defaultAddress.longitude,
            typeOfAddress: 0,
            isDefault: true,
          };
          await AddAddress.insertAddress(addressData, Login.result.d1Id);
          const addData = await AddAddress.getAddress(Login.result.d1Id);
          setAddAddresses(addData)
          localStorage.removeItem("DefaultAddress");
        }
        catch (error) {
          console.log(error);
        }
        if (CompanyDetail.isEnableGoogleMap) {
          if (defaultAdd[0]?.distance == 0 || defaultAdd[0]?.distance == null || defaultAdd[0]?.distance == undefined) {
            alert("The distance is 0. Please select an address from Google Maps");
          }
        }
      }
      // navigate("/MyAccountPage");
    } else {
      alert("Wrong Id and Password");
    }
  };



  const LoginAccountOT = async () => {
    setLoading(true)
    var storedOrderTakerID = localStorage.getItem("OrderTakerId");
    const Login = await LoginApi.LogInAccountOT(inputValuesForOTLogin, storedOrderTakerID);
    // debugger
    if (Login) {
      props.close();
      localStorage.setItem("accountLogin", JSON.stringify(Login.result.result));
      //fetchCartdata()
      await Order.UpdateClientIdByAccountId(Login.result.result)
      setLoginShow(false);
      //fetchCartdata()
      alert(`Welcome ${Login.result.result.name}`);

      const updatedValues = {
        orderType: 751,
        deliveryPointName: 1,
        distance: 0,
        isCurrentDate: true
      };
      const serviceTypeData = await Order.getServiceType();
      fetchAllotedTimeSlot(updatedValues, serviceTypeData)


      const storedArrayString = localStorage.getItem("DefaultAddress");
      if (storedArrayString) {
        try {
          const defaultAddress = JSON.parse(storedArrayString);

          const addressData = {
            name: Login.result.name,
            mobile: Login.result.mobileNo,
            pincode: defaultAddress.pincode,
            state: 0,
            address: defaultAddress.address,
            locality: "",
            city: "",
            latitude: defaultAddress.latitude,
            longitude: defaultAddress.longitude,
            typeOfAddress: 0,
            isDefault: true,
          };
          await AddAddress.insertAddress(addressData, Login.result.d1Id);
          const addData = await AddAddress.getAddress(Login.result.d1Id);
          setAddAddresses(addData)
          localStorage.removeItem("DefaultAddress");
        }
        catch (error) {
          console.log(error);
        }
      }

      // navigate("/MyAccountPage");
      setLoading(false)
    } else {
      alert("Wrong Id and Password");
      setLoading(false)
    }
  };

  const handleInputChangeOTP = (event) => {
    const { name, value } = event.target;
    // console.log(`Changing ${name} to ${value}`);
    setInputValuesForOTP((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const SignInAccount = async () => {
    const VerifyOtp = await OTPinfo.VerifyOtp(inputValuesForSignIn, inputValuesForOTP)
    console.log(VerifyOtp)
    alert(VerifyOtp);
    if (VerifyOtp !== "OTP failed to verify! Incorrect OTP!") {
      const signin = await LoginApi.insertAccount(inputValuesForSignIn);
      if (signin.isSuccess) {
        const Login = await LoginApi.AutoLogInAccount(inputValuesForSignIn);
        if (Login) {
          messageWhatsAppFunction("on_user_registration", 0, Login.result.d1Id, Login.result.mobileNo)
          props.close();
          await Order.UpdateClientIdByAccountId(Login.result)
          localStorage.setItem("accountLogin", JSON.stringify(Login.result));
          alert(`Welcome ${Login.result.name}`);
          const storedArrayString = localStorage.getItem("DefaultAddress");
          if (storedArrayString) {
            try {
              const defaultAddress = JSON.parse(storedArrayString);

              const addressData = {
                name: Login.result.name,
                mobile: Login.result.mobileNo,
                pincode: defaultAddress.pincode,
                state: 0,
                address: defaultAddress.address,
                locality: "",
                city: "",
                latitude: defaultAddress.latitude,
                longitude: defaultAddress.longitude,
                typeOfAddress: 0,
                isDefault: true,
              };
              await AddAddress.insertAddress(addressData, Login.result.d1Id);
              const addData = await AddAddress.getAddress(Login.result.d1Id);
              setAddAddresses(addData)
              localStorage.removeItem("DefaultAddress");
            }
            catch (error) {
              console.log(error);
            }
          }
          setLoginShow(false);
          // navigate("/MyAccountPage");
        } else {
          alert("Wrong Id and Password");
        }
        props.close()
      } else {
        alert("Account Already Exist");
      }
    }
  };

  const openResetpassword = () => {
    ResetPasswordUI()
  }

  const ChangePassword = async () => {

    if (inputValuesForSignIn.password === inputValuesForSignIn.reEnterpassword) {
      // debugger
      var checkType = ""
      if (inputValuesForSignIn.aEmail.includes('@')) {
        checkType = "Email"
      }
      else if (/^[\d\s-()+]+$/.test(inputValuesForSignIn.aEmail)) {
        checkType = "MobileNo"
      }

      let requestData = {};

      if (checkType === "Email") {
        requestData = [{
          "fieldName": "email",
          "operatorName": "equal",
          "compareValue": inputValuesForSignIn.aEmail
        }];
      } else if (checkType === "MobileNo") {
        requestData = [{
          "fieldName": "mobileNo",
          "operatorName": "equal",
          "compareValue": inputValuesForSignIn.aEmail
        }];
      }
      const accountData = await Account.getAccountWithFilter(requestData)

      console.log(accountData.length)
      if (accountData.length > 0) {
        var PasswordUpdate = [
          {
            "operationType": 0,
            "path": "Password",
            "op": "add",
            "from": "string",
            "value": inputValuesForSignIn.password
          }
        ]

        var ChangePassword = await Account.PartiallyUpdateAccountForOTP(PasswordUpdate, accountData)
        if (ChangePassword) {
          alert("Your Password Updated Successfully")
          signupshow()
        }
        else {
          alert("Something Went Wrong")
        }
      }
    }
    else {
      alert("Password and Re-enter password does not match")
    }
  }
  useImperativeHandle(ref, () => ({}));


  return (
    <div className="modal-back">
      <div className="account-modal">
        <i
          onClick={props.close}
          style={{ color: "white", cursor: "pointer", zIndex: "1" }}
          className="fa-solid fa-xmark card-menu-favorite"
        ></i>

        {loginPanel && (
          <LoginPanel
            signupshow1={signupshow1}
            LoginAccount={LoginAccount}
            LoginAccountOT={LoginAccountOT}
            forgotPaggword={forgotPaggword}
            inputValuesForLogin={inputValuesForLogin}
            inputValuesForOTLogin={inputValuesForOTLogin}
            handleInputChangeLogin={handleInputChangeLogin}
            handleInputChangeLoginOT={handleInputChangeLoginOT}
          />
        )}

        {signin && (
          <SignInPanel
            showPassword1={showPassword1}
            handleInputChangeSignIn={handleInputChangeSignIn}
            inputValuesForSignIn={inputValuesForSignIn}
            signupshow={signupshow}
            SendOtp={SendOtp}
            handleTogglePasswordhide={handleTogglePasswordhide}
            showPassword={showPassword}
            handleTogglePasswordhide1={handleTogglePasswordhide1}
            handleTogglePassword={handleTogglePassword}
            handleTogglePassword1={handleTogglePassword1}
          />
        )}

        {getOtp && (
          <GetOTPPanel
            SignInAccount={SignInAccount}
            secondsLeft={secondsLeft}
            isDisabled={isDisabled}
            handleClick={handleClick}
            visibleResendOTP={visibleResendOTP}
            inputValuesForOTP={inputValuesForOTP}
            handleInputChangeOTP={handleInputChangeOTP}
          />
        )}

        {forgot && (
          <ForgotPasswordPanel
            handleInputChangeSignIn={handleInputChangeSignIn}
            inputValuesForSignIn={inputValuesForSignIn}
            VerifyOTP={VerifyOTP}
            inputValuesForOTP={inputValuesForOTP}
            secondsLeft={secondsLeft}
            handleInputChangeOTP={handleInputChangeOTP}
            isDisabled={isDisabled}
            handleClick={handleClick}
            visibleResendOTP={visibleResendOTP}
            SendOtpForForgotPassword={SendOtpForForgotPassword}
          />
        )}

        {ResetPassword && (
          <ReserPasswordPanel
            ChangePassword={ChangePassword}
            handleTogglePassword={handleTogglePassword}
            handleTogglePasswordhide={handleTogglePasswordhide}
            showPassword={showPassword}
            handleTogglePasswordhide1={handleTogglePasswordhide1}
            handleTogglePassword1={handleTogglePassword1}
            inputValuesForSignIn={inputValuesForSignIn}
            showPassword1={showPassword1}
            handleInputChangeSignIn={handleInputChangeSignIn}
          />
        )}
        {loading && <Loader />}
      </div>
    </div>
  );
});

export default MyAccount;
