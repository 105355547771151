import React, { useContext, useEffect, useState } from "react";
import VariableProductModal from "./VariableProductModal";
import MyContext from "./MyContext";
import ItemMasterApi from "../API/API_SERVISES/ItemMaster";
import { format } from "date-fns";
import Order from "../API/API_SERVISES/Order";
import useIntersectionObserver from "./useIntersectionObserver "
import moment from "moment";
import CurrentDateAndTime from "../API/API_SERVISES/CurrentDateAndTime";

const ProductCardMenu = (props) => {
  const {
    product,
    onQuantityChange,
    cartData,
    imageBaseUrl,
    addingItemInCart,
    RemoveItemInCart,
    productlist1,
    AddToCart,
    currentTimeData,
    RemoveFromCart,
    currentDate
  } = props;

  const [imageSrc, setImageSrc] = useState('');

  const loadImage = (element) => {
    if (product && product.images) {
      setImageSrc(`${imageBaseUrl}${product.images[0]}`);
    }
  };

  const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const { selectedService, wishList, loginData, myaccountmodal, AllottedTimeSlot, buttonClickCounts, setButtonClickCounts, fetchDataWish } = useContext(MyContext);
  const [VariableItemData, setVariableItemData] = useState(false);
  const [parantData, setParantData] = useState();
  const [DataToSend, setDataToSend] = useState({});

  const OpenVariableitem = (productId) => {
    const filteredArray = productlist1.filter(
      (item) => productId === item.attributeParentId
    );
    const parantData = productlist1.find((item) => productId === item.d2Id);

    setDataToSend(filteredArray);
    setParantData(parantData);
    setVariableItemData(true);
  };


  const [itemName, setItemName] = useState();
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);

  const handleButtonClick = () => {
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 500);
  };

  const handleButtonClick1 = () => {
    setShowToast1(true);

    setTimeout(() => {
      setShowToast1(false);
    }, 500);
  };

  const isDateWithinRange = async (availableStartDate, availableEndDate) => {
    // const currentDate = new Date();
    const currentDate = await CurrentDateAndTime.getCurrentDate()
    const startDate = new Date(availableStartDate);
    const endDate = new Date(availableEndDate);
    //alert(`startDate- ${startDate}`)
    return currentDate >= startDate && currentDate <= endDate;
  };

  const parseTimeString = (timeString) => {
    if (!timeString) {
      return null;
    }
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
  };

  const isTimeWithinRange = (availableTimeFrom, availableTimeTo) => {
    const timeId = localStorage.getItem("deliveryTime");
    if (AllottedTimeSlot && AllottedTimeSlot.length > 0) {

      const allotedData = AllottedTimeSlot?.filter(item => item.id == timeId)
      // console.log(allotedData);
      const currentTime = allotedData && allotedData.length > 0 ? allotedData[0].availableTo : currentTimeData;

      const fromTime = availableTimeFrom;
      const toTime = availableTimeTo;
      if (currentTime === null) {
        console.error('Invalid time format detected');
        return false;
      }
      return currentTime >= fromTime && currentTime <= toTime;
    }
    // console.log(availableTimeFrom);
    // console.log(availableTimeTo);
    // console.log(currentTime);
    // console.log(currentTime >= fromTime && currentTime <= toTime);
  };


  const [isAvailable, setIsAvailable] = useState(null); // Initial state can be null or a default value


  async function isDateAvailable(item) {
    const currentDateToCheck = localStorage.getItem("deliverydate") || currentDate;
    // const currentDate1 = new Date(currentDate);

    // Handle cases where startDate or endDate is null, undefined, or blank
    const startDate = item.availableDatefrom ? new Date(moment(item.availableDatefrom).format('YYYY-MM-DD')) : null;
    const endDate = item.availableDateTo ? new Date(moment(item.availableDateTo).format('YYYY-MM-DD')) : null;

    // If either startDate or endDate is null, return true
    if (startDate === null || endDate === null) {
      return true;
    }
    // console.log(currentDate1);
    // Check if the current date falls within the range
    // console.log(startDate);
    // console.log(currentDate1);
    // console.log(endDate);
    const isAvailable = currentDateToCheck >= startDate && currentDateToCheck <= endDate;
    // console.log(item.name, '---', isAvailable);
    return isAvailable;
  }

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const availability = await isDateAvailable(product);
        setIsAvailable(availability);
      } catch (error) {
        console.error("Error checking availability:", error);
        setIsAvailable(false); // Handle the error as needed
      }
    };
    fetchAvailability();
  }, [product, currentDate]); // Re-run effect when product changes


  // const isAvailableNow = (product.restoAvailableTime.length === 0) ? true : product.restoAvailableTime.some(async (timeSlot) => {
  //   debugger
  //   // Check if both availableTimefrom and availableTimeTo are null
  //   if (timeSlot.availableTimefrom === null && timeSlot.availableTimeTo === null || timeSlot.availableTimefrom === "" && timeSlot.availableTimeTo === "") {
  //     return true; // Consider the product as available
  //   } else {
  //     console.log(await isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo));
  //     return await isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo);
  //   }
  // });

  const handleIncrementValue = (id, Item, isAttrbute, ParentId, product) => {
    var carditem = cartData.find(item => (item.d2ItemId === id))
    if (carditem?.quantity >= product.restoAvailableQuantity && product.stockCheck === 1) {
      alert(`Out of Stock Only ${product.restoAvailableQuantity} Quantity available in stock`);
    }
    else {
      const parantData = productlist1.find((item) => id === item.d2Id);
      if (parantData.attributeParentId === 0 &&
        parantData.isAttributeItem === true) {
        OpenVariableitem(id);
      } else {
        setItemName(Item);
        handleButtonClick();
        AddToCart(product)
      }
    }
  }

  const handleDecrementValue = (id, itemName, ParentId, product) => {
    RemoveFromCart(product);
    setItemName(itemName);
    handleButtonClick1();
  };

  useEffect(() => {
    if (cartData === undefined) {
      // // // console.log("if");
      localStorage.setItem("cartData", "");
    } else {
      localStorage.setItem("cartData", JSON.stringify(cartData));
    }
  }, [cartData]);

  const handleIncrement1 = (productId, currentQuantity) => {
    const updatedQuantity = Number(currentQuantity) + 1;
    onQuantityChange(productId, updatedQuantity);
    setVariableItemData(false);
  };

  const variableClose = () => {
    setVariableItemData(false);
  };

  const [, setInputValues] = useState({
    d1ItemId: "",
    d2ItemId: "",
    d1AccountId: "",
    d2AccountId: "",
    userId: "",
  });


  const toggleWishlist = async (itemData) => {
    if (loginData) {
      if (loginData.d1Id != undefined) {
        const existingIndex = wishList && wishList.find(product => product.d1ItemId === itemData.d1Id);
        console.log(existingIndex);
        if (existingIndex !== undefined) {
          // alert("Item is already in wishlist");
          try {
            const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
            if (Boolean(val) === true) {
              // alert("Delete data successfully", "Ok");
              fetchDataWish()
            } else {
              alert("Error Deleting Data");
            }
            // fetchData();
          } catch (error) {
            console.error("Error deleting account:", error);
          }
          return;
        } else {
          const data = {
            d1ItemId: itemData.d1Id,
            d2ItemId: itemData.d2Id,
            d1AccountId: loginData.d1Id,
            d2AccountId: loginData.d2Id,
            userId: 1,
          };
          try {
            const isSuccess = await Order.insertCreateFavouriteItemList(data);
            if (isSuccess) {
              // alert("Added to wishlist successfully");
              fetchDataWish()
            } else {
              throw new Error("Error while adding to wishlist");
            }
          } catch (error) {
            console.error("Error while adding to wishlist:", error);
            alert("Error while adding to wishlist");
          }
          setInputValues({
            d1ItemId: "",
            d2ItemId: "",
            d1AccountId: "",
            d2AccountId: "",
            userId: "",
          });

        }
      }
      else {
        myaccountmodal();
      }
    }
    else {
      myaccountmodal();
    }
  }

  // const currentTime = new Date(); // Current time

  return (
    <>
      <div
        className="card-menu"
        style={{
          opacity:
            selectedService == 751
              ? (product && product.availableForTableOrder === false ||
                product.restoAvailableQuantity === cartData && cartData.find(
                  item => item.d2ItemId === product.d2Id || item.d2ItemId === product.d2ItemId
                )?.quantity && product.stockCheck === 1 || !isAvailable ||
                (product.restoAvailableQuantity === 0 && product.stockCheck === 1)
              )
                ? "0.5"
                : product.restoAvailableTime.some((timeSlot) => {
                  // Check if both availableTimefrom and availableTimeTo are null
                  if (timeSlot.availableTimefrom === null && timeSlot.availableTimeTo === null) {
                    return true; // Consider product available if both are null
                  }
                  // Otherwise, check if availableTimefrom and availableTimeTo are defined and within range
                  return timeSlot.availableTimefrom && timeSlot.availableTimeTo && (isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) == true);
                })
                  ? "1"
                  : "0.5"
              : selectedService == 752
                ? (product.availableForHomeDelivery === false ||
                  product.restoAvailableQuantity === cartData.find(
                    item => item.d2ItemId === product.d2Id || item.d2ItemId === product.d2ItemId
                  )?.quantity && product.stockCheck === 1 || !isAvailable ||
                  (product.restoAvailableQuantity === 0 && product.stockCheck === 1)
                )
                  ? "0.5"
                  : product.restoAvailableTime.some((timeSlot) => {
                    // Check if both availableTimefrom and availableTimeTo are null
                    if (timeSlot.availableTimefrom === null && timeSlot.availableTimeTo === null) {
                      return true; // Consider product available if both are null
                    }
                    // Otherwise, check if availableTimefrom and availableTimeTo are defined and within range
                    return timeSlot.availableTimefrom && timeSlot.availableTimeTo && (isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) == true);
                  })
                    ? "1"
                    : "0.5"
                : selectedService == 753
                  ? (product.availableForPickUp === false || product.restoAvailableQuantity === cartData.find(
                    item => item.d2ItemId === product.d2Id || item.d2ItemId === product.d2ItemId
                  )?.quantity && product.stockCheck === 1 || !isAvailable ||
                    (product.restoAvailableQuantity === 0 && product.stockCheck === 1)
                  )
                    ? "0.5"
                    : product.restoAvailableTime.some((timeSlot) => {
                      // Check if both availableTimefrom and availableTimeTo are null
                      if (timeSlot.availableTimefrom === null && timeSlot.availableTimeTo === null) {
                        return true; // Consider product available if both are null
                      }
                      // Otherwise, check if availableTimefrom and availableTimeTo are defined and within range
                      return timeSlot.availableTimefrom && timeSlot.availableTimeTo && (isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) == true);
                    })
                      ? "1"
                      : "0.5"
                  : "1" // Default opacity if none of the service types match
        }}
      >
        {/* {isAvailable ? "true" : "false"} <br />
        {product.d1Id} */}

        {/* {product.restoAvailableTime.some((timeSlot) => {
          // Check if both availableTimefrom and availableTimeTo are null
          if (timeSlot.availableTimefrom === null && timeSlot.availableTimeTo === null) {
            return true; // Consider product available if both are null
          }
          // Otherwise, check if availableTimefrom and availableTimeTo are defined and within range
          return timeSlot.availableTimefrom && timeSlot.availableTimeTo && isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo);
        }) ? 'available' : 'not available'} */}


        {(wishList && wishList.find(product1 => product1.d1ItemId === product.d1Id)) ? (
          <i
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => toggleWishlist(product)}
            className="fa-solid fa-heart card-menu-favorite"
          ></i>
        ) : (
          <i
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => toggleWishlist(product)}
            className="fa-regular fa-heart card-menu-favorite"
          ></i>
        )}
        <div className="card-menu-1">
          <div className="menu-image-box">
            <img
              ref={imageRef}
              className="card-menu-image"
              src={imageSrc}
              alt=""
            />
          </div>
          <div className="menu-image-box-1">
            <div>
              <div className="">
                <p className="menu-card-title-name">{product == null ? "" : product.name}</p>
              </div>
              {product.shortDescription && product.shortDescription != "" ? (

                <div className="shortDescriptionBox">
                  {/* <p className="menu-card-detail">{product == null ? "" : product.shortDescription}</p> */}
                  <p className={`${isExpanded ? "expanded" : "collapsed"} menu-card-detail`}
                    dangerouslySetInnerHTML={{ __html: product.shortDescription }} />

                  <button onClick={toggleText} className="ShowMorebuttonPCM">
                    {isExpanded ? "Less" : "More"}
                  </button>

                </div>
              ) : ("")}
              <div className="">
                <p className="menu-card-price">
                  price : <i
                    style={{ marginRight: "3px" }}
                    className="fa-solid fa-indian-rupee-sign rupees-icon"
                  ></i>
                  {product == null ? "" : product.salePrice}
                </p>
              </div>
            </div>
            <div>
              {/* table order */}
              {selectedService == "751" && (
                <>
                  {product == null ? (
                    ""
                  ) : product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                    <p className="ourmenu-productnotavli">Out Of stock</p>
                  ) : (
                    <>
                      {product.restoAvailableTime && product.restoAvailableTime.length > 0 && product.availableForTableOrder === true ? (
                        <>
                          {product.restoAvailableTime.some((timeSlot) => {
                            const available = timeSlot.availableTimefrom && timeSlot.availableTimeTo;
                            return available ? isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) == true : true;
                          }) ? (
                            product.restoAvailableTime.map((timeSlot, index) => (
                              <span key={index}>
                                {!isAvailable || (timeSlot.availableTimefrom && timeSlot.availableTimeTo) ? (
                                  (isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) == true) && (
                                    <div className="quantity-box">
                                      <p className="menu-card-price">
                                        <div className="quantity">
                                          <button
                                            style={{ paddingBottom: "4px" }}
                                            className="quantity__minus"
                                            onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                          >
                                            -
                                          </button>
                                          <input
                                            className="quantity__input"
                                            type="text"
                                            value={buttonClickCounts[product.d2Id] || 0}
                                            readOnly
                                          />
                                          <button
                                            className="quantity__plus"
                                            onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                          >
                                            +
                                          </button>
                                        </div>
                                        {product.isAttributeItem ? (
                                          <span
                                            style={{
                                              marginLeft: "8px",
                                              marginTop: "2px",
                                              fontSize: "10px",
                                              color: "gray",
                                            }}
                                            className="menu-card-detail"
                                          >
                                            Customisable
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </p>
                                      {product.restoAvailableQuantity === cartData.find(item1 =>
                                        (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2Id)
                                      )?.quantity && product.stockCheck === 1 ? (
                                        <p className="ourmenu-productnotavli">Out Of stock</p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <div className="quantity-box">
                                    <p className="menu-card-price">
                                      <div className="quantity">
                                        <button
                                          style={{ paddingBottom: "4px" }}
                                          className="quantity__minus"
                                          onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="quantity__input"
                                          type="text"
                                          value={buttonClickCounts[product.d2Id] || 0}
                                          readOnly
                                        />
                                        <button
                                          className="quantity__plus"
                                          onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                        >
                                          +
                                        </button>
                                      </div>
                                      {product.isAttributeItem ? (
                                        <span
                                          style={{
                                            marginLeft: "8px",
                                            marginTop: "2px",
                                            fontSize: "10px",
                                            color: "gray",
                                          }}
                                          className="menu-card-detail"
                                        >
                                          Customisable
                                        </span>
                                      ) : (
                                        <span></span>
                                      )}
                                    </p>
                                    {product.restoAvailableQuantity === cartData.find(item1 =>
                                      (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2Id)
                                    )?.quantity && product.stockCheck === 1 ? (
                                      <p className="ourmenu-productnotavli">Out Of stock</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </span>
                            ))
                          ) : (
                            <>
                              {isAvailable && (
                                <p className="menu-card-price" style={{ color: 'red', marginBottom: "4px" }}>Not Available Now</p>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {isAvailable ? (
                        // <p style={{ color: 'green', marginLeft: '10px' }}>Available Today!</p>
                        <></>
                      ) : (
                        <p className="ourmenu-productnotavli">Not Available Today</p>
                      )}

                      {product != null && product.availableForTableOrder === false && (
                        <p className="ourmenu-productnotavli">Not Available For Table Order</p>
                      )}
                    </>
                  )}
                </>
              )}

              {/* Home delivery order */}
              {selectedService == "752" && (
                <>
                  {product == null ? "" : product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                    <p className="ourmenu-productnotavli">Out Of stock</p>
                  ) : (
                    <>
                      {product.restoAvailableTime && product.restoAvailableTime.length > 0 && product.availableForHomeDelivery === true ? (
                        <>
                          {product.restoAvailableTime.some((timeSlot) => {
                            const available = timeSlot.availableTimefrom && timeSlot.availableTimeTo;
                            return available ? isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) == true : true;
                          }) ? (
                            product.restoAvailableTime.map((timeSlot, index) => (
                              <span key={index}>
                                {!isAvailable || (timeSlot.availableTimefrom && timeSlot.availableTimeTo) ? (
                                  (isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) == true) && (
                                    <div className="quantity-box">
                                      <p className="menu-card-price">
                                        <div className="quantity">
                                          <button
                                            style={{ paddingBottom: "4px" }}
                                            className="quantity__minus"
                                            onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                          >
                                            -
                                          </button>
                                          <input
                                            className="quantity__input"
                                            type="text"
                                            value={buttonClickCounts[product.d2Id] || 0}
                                            readOnly
                                          />
                                          <button
                                            className="quantity__plus"
                                            onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                          >
                                            +
                                          </button>
                                        </div>
                                        {product.isAttributeItem ? (
                                          <span
                                            style={{
                                              marginLeft: "8px",
                                              marginTop: "2px",
                                              fontSize: "10px",
                                              color: "gray",
                                            }}
                                            className="menu-card-detail"
                                          >
                                            Customisable
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </p>
                                      {product.restoAvailableQuantity === cartData.find(item1 =>
                                        (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                      )?.quantity && product.stockCheck === 1 ? (
                                        <p className="ourmenu-productnotavli">Out Of stock</p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <div className="quantity-box">
                                    <p className="menu-card-price">
                                      <div className="quantity">
                                        <button
                                          style={{ paddingBottom: "4px" }}
                                          className="quantity__minus"
                                          onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="quantity__input"
                                          type="text"
                                          value={buttonClickCounts[product.d2Id] || 0}
                                          readOnly
                                        />
                                        <button
                                          className="quantity__plus"
                                          onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                        >
                                          +
                                        </button>
                                      </div>
                                      {product.isAttributeItem ? (
                                        <span
                                          style={{
                                            marginLeft: "8px",
                                            marginTop: "2px",
                                            fontSize: "10px",
                                            color: "gray",
                                          }}
                                          className="menu-card-detail"
                                        >
                                          Customisable
                                        </span>
                                      ) : (
                                        <span></span>
                                      )}
                                    </p>
                                    {product.restoAvailableQuantity === cartData.find(item1 =>
                                      (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                    )?.quantity && product.stockCheck === 1 ? (
                                      <p className="ourmenu-productnotavli">Out Of stock</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </span>
                            ))
                          ) : (
                            <>
                              {isAvailable && (
                                <p className="menu-card-price" style={{ color: 'red', marginBottom: "4px" }}>Not Available Now</p>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {isAvailable ? (
                        // <p style={{ color: 'green', marginLeft: '10px' }}>Available Today!</p>
                        <></>
                      ) : (<p className="ourmenu-productnotavli" >Not Available Today</p>
                      )
                      }

                      {product != null && product.availableForHomeDelivery === false && (
                        <p className="ourmenu-productnotavli">Not Available For Home Delivery</p>
                      )}
                    </>
                  )}
                </>
              )}

              {/* Pick Up order */}
              {selectedService == "753" && (
                <>
                  {product == null ? "" : product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                    <p className="ourmenu-productnotavli">Out Of stock</p>
                  ) : (
                    <>
                      {product.restoAvailableTime && product.restoAvailableTime.length > 0 && product.availableForPickUp === true ? (
                        <>
                          {product.restoAvailableTime.some((timeSlot) => {
                            const available = timeSlot.availableTimefrom && timeSlot.availableTimeTo;
                            return available ? isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) == true : true;
                          }) ? (
                            product.restoAvailableTime.map((timeSlot, index) => (
                              <span key={index}>
                                {!isAvailable || (timeSlot.availableTimefrom && timeSlot.availableTimeTo) ? (
                                  (isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) == true) && (
                                    <div className="quantity-box">
                                      <p className="menu-card-price">
                                        <div className="quantity">
                                          <button
                                            style={{ paddingBottom: "4px" }}
                                            className="quantity__minus"
                                            onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                          >
                                            -
                                          </button>
                                          <input
                                            className="quantity__input"
                                            type="text"
                                            value={buttonClickCounts[product.d2Id] || 0}
                                            readOnly
                                          />
                                          <button
                                            className="quantity__plus"
                                            onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                          >
                                            +
                                          </button>
                                        </div>
                                        {product.isAttributeItem ? (
                                          <span
                                            style={{
                                              marginLeft: "8px",
                                              marginTop: "2px",
                                              fontSize: "10px",
                                              color: "gray",
                                            }}
                                            className="menu-card-detail"
                                          >
                                            Customisable
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </p>
                                      {product.restoAvailableQuantity === cartData.find(item1 =>
                                        (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                      )?.quantity && product.stockCheck === 1 ? (
                                        <p className="ourmenu-productnotavli">Out Of stock</p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <div className="quantity-box">
                                    <p className="menu-card-price">
                                      <div className="quantity">
                                        <button
                                          style={{ paddingBottom: "4px" }}
                                          className="quantity__minus"
                                          onClick={() => handleDecrementValue(product.d2Id, product.name, product.attributeParentId, product)}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="quantity__input"
                                          type="text"
                                          value={buttonClickCounts[product.d2Id] || 0}
                                          readOnly
                                        />
                                        <button
                                          className="quantity__plus"
                                          onClick={() => handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId, product)}
                                        >
                                          +
                                        </button>
                                      </div>
                                      {product.isAttributeItem ? (
                                        <span
                                          style={{
                                            marginLeft: "8px",
                                            marginTop: "2px",
                                            fontSize: "10px",
                                            color: "gray",
                                          }}
                                          className="menu-card-detail"
                                        >
                                          Customisable
                                        </span>
                                      ) : (
                                        <span></span>
                                      )}
                                    </p>
                                    {product.restoAvailableQuantity === cartData.find(item1 =>
                                      (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                    )?.quantity && product.stockCheck === 1 ? (
                                      <p className="ourmenu-productnotavli">Out Of stock</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </span>
                            ))
                          ) : (<>
                            {isAvailable && (
                              <p className="menu-card-price" style={{ color: 'red', marginBottom: "4px" }}>Not Available Now</p>
                            )}
                          </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {isAvailable ? (
                        // <p style={{ color: 'green', marginLeft: '10px' }}>Available Today!</p>
                        <></>
                      ) : (<p className="ourmenu-productnotavli" >Not Available Today</p>)
                      }
                      {product != null && product.availableForPickUp === false && (
                        <p className="ourmenu-productnotavli">Not Available For Pick Up</p>
                      )}
                    </>
                  )}
                </>
              )}



            </div>
          </div>
        </div>
        {/* } else {
          console.error("Product or product images are undefined.")
        } */}
      </div>
      {showToast && (
        <div className="toastDiv">{itemName} Added Successfully !!</div>
      )}
      {showToast1 && (
        <div className="toastDiv">Removed {itemName} !!</div>
      )}
      {VariableItemData && (
        <div className="variableproduct-modal-back">
          <VariableProductModal
            parantData={parantData}
            product={product == null ? "" : product}
            data={DataToSend}
            onClick={OpenVariableitem}
            RemoveFromCart={RemoveFromCart}
            AddToCart={AddToCart}
            handleIncrementValue={handleIncrementValue}
            variableClose={variableClose}
            handleDecrementValue={handleDecrementValue}
            buttonClickCounts={buttonClickCounts}
            cartData={cartData}
            selectedService={selectedService}
            isDateWithinRange={isDateWithinRange}
            isTimeWithinRange={isTimeWithinRange}
          />
        </div>
      )}
    </>
  );
};

export default ProductCardMenu;
