import React, { useContext, useEffect, useState } from "react";
import WebTemplate from "../API/API_SERVISES/WebTemplate";
import MyContext from "./MyContext";
// import { useParams } from "react-router-dom";
// import styles from "../Css/HomePage.Module.css"
// import styles1 from "../Css/Edit.Module.css"
// import styles2 from "../Css/Draggable.Module.css"

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;


var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Footer = () => {
  const [apiData, setApiData] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [styleArray, setStylesArray] = useState([]);

  const { currentUser } = useContext(MyContext)

  useEffect(() => {
    console.log("styleArray", styleArray);
    console.log("apiData", apiData);
    console.log("droppedItems", droppedItems);
  }, [apiData, droppedItems, styleArray]);

  function replaceHostNameAndPort(data, newHostName, newPort) {
    if (typeof data === 'string') {
      return data.replace(/(?:https?:\/\/)?([^\/:]+)(?::(\d+))?/g, (match, oldHostName, oldPort) => {
        if (oldPort) {
          return `${newHostName}:${newPort}`;
        } else {
          return `${newHostName}`;
        }
      });
    }
    if (Array.isArray(data)) {
      return data.map(item => replaceHostNameAndPort(item, newHostName, newPort));
    }
    if (typeof data === 'object' && data !== null) {
      const updatedData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          updatedData[key] = replaceHostNameAndPort(data[key], newHostName, newPort);
        }
      }
      return updatedData;
    }
    return data;
  }

  const showTemplateData = async (e) => {
    const filteredData = [{
      "fieldName": "name",
      "operatorName": "equal",
      "compareValue": "Footer" || "Dynamic_Component"  //
    }];
    var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
    if (getdata) {
      if (getdata.jsonStructure) {
        try {
          const decodedData = decodeURIComponent(getdata.jsonStructure)
          const parsedData = JSON.parse(decodedData);
          // getdata.jsonStructure = parsedData
          setDroppedItems(parsedData);
          if (getdata.textContents) {
            const decodedData = decodeURIComponent(getdata.textContents)
            let parsedData = JSON.parse(decodedData);

            for (let i = 0; i < parsedData.length; i++) {
              // Check if the 'background-image' property exists in the style object
              if (parsedData[i].style && parsedData[i].style['background-image']) {
                let originalUrl = parsedData[i].style['background-image'];

                // Check if the value of 'background-image' is a URL (it should start with "url(")
                if (originalUrl.startsWith('url(')) {
                  // Extract the URL from the background-image (remove "url(" and ")")
                  const urlMatch = originalUrl.match(/url\(["']?(.*?)["']?\)/);
                  if (urlMatch && urlMatch[1]) {
                    let extractedUrl = urlMatch[1];

                    // Create a URL object from the extracted URL to dynamically replace protocol, hostname, and port
                    const url = new URL(extractedUrl);

                    // Create the new URL with the current protocol, hostname, and port
                    const newImageUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${url.pathname}${url.search}${url.hash}`;

                    // Update the 'background-image' with the new URL
                    parsedData[i].style['background-image'] = `url(${newImageUrl})`;
                  }
                }
              }
            }

            setStylesArray(parsedData);
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
          setDroppedItems([]);
        }
      } else {
        setDroppedItems([]);
      }
      var templateInnerHtml = getdata.textContents;
      var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
      getdata.textContents = templateInnerHtmlDecode

      setApiData(getdata);

      // setDataHtml(getdata)

    } else {
      // document.getElementById("drop-target-innerHtml").outerHTML = "";
      setDroppedItems([]);
    }
  }
  useEffect(() => {
    if (currentUser == true) {
      showTemplateData()
    }
  }, [currentUser]);

  useEffect(() => {
    // Use a for loop to iterate over stylesArray
    for (let index = 0; index < styleArray.length; index++) {
      const styleObject = styleArray[index];
      console.log(styleObject.id);

      const element = document.getElementById(styleObject.id);

      if (element) {
        for (const [key, value] of Object.entries(styleObject.style)) {
          // Convert kebab-case to camelCase for inline styles
          const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
          element.style[camelCaseKey] = value;
        }
      }
    }
  }, [styleArray]);


  const renderDroppedItems = () => {
    return droppedItems.length > 0 && droppedItems.some(item => item.items.length > 0) ? (
      droppedItems.map((item, index) => {
        if (item.items.length > 0) {
          return (
            <div key={index} dangerouslySetInnerHTML={{ __html: item.textContents }} />
          );
        }
        return null;
      })
    ) : null;
  };

  return (
    <div style={{ backgroundColor: "#000000", marginTop: "15px" }} >
      {renderDroppedItems()}
    </div>
  )
}


export default Footer;
