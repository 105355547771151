import React, { useContext, useEffect, useState } from "react";
import AddAddressModal from "./AddAddressModal";
import AddAddressModalProfle from "./AddAddressModalProfle";
import AddAddress from "../API/API_SERVISES/AddAddress";
import Loader from "./Loader";
import MyContext from "./MyContext";
import CurrentDateAndTime from "../API/API_SERVISES/CurrentDateAndTime";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import Order from "../API/API_SERVISES/Order";

const MyAccountComponent2Address = (props) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addresses, setAddAddresses, openmainmodal, setAllottedTimeSlot, AllottedTimeSlot, CompanyDetail } = useContext(MyContext)
  const { setAllotedTimeSlot, getDeliveryAndTimeSlotId, setDeliveryAndTimeSlotId } = props;


  const [inputValues, setInputValues] = useState({
    accountId: "",
    name: "",
    mobile: "",
    pincode: "",
    state: "",
    address: "",
    locality: "",
    city: "",
    typeOfAddress: 0,
    isDefault: "",
    isActive: true,
  });

  const { DefaultAddressChange, setDefaultAddressChange } = useContext(MyContext)

  const getCurrentDate = async () => {
    const date = await CurrentDateAndTime.getCurrentDate()
    return date;
  };

  const handleCheckboxChange = async (addressIndex, id) => {
    var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    var loginData = JSON.parse(loginDataString);

    var getaddress = await AddAddress.getAddressById(id, loginData.d1Id);
    if (getaddress.isDefault == false) {
      const updatedValues = {
        ...getaddress,
        "isDefault": true,
      };
      await AddAddress.updateAddress(updatedValues, loginData.d1Id);
    }
    setDefaultAddressChange(!DefaultAddressChange)

    const selectedDate = localStorage.getItem("deliverydate")
    const currentDate = await getCurrentDate();
    var isCrntDate;
    if (currentDate == selectedDate)
      isCrntDate = true
    else
      isCrntDate = false

    let updatedValues = {
      ...getDeliveryAndTimeSlotId,
      distance: getaddress.distance,
      isCurrentDate: isCrntDate,
    };
    // setDeliveryAndTimeSlotId({
    //   ...getDeliveryAndTimeSlotId,
    //   distance: getaddress.distance,
    //   isCurrentDate: isCrntDate,
    // });

    if (CompanyDetail.isEnableGoogleMap) {
      if (getaddress.distance == 0 || getaddress.distance == null || getaddress.distance == undefined) {
        alert("The distance is 0. Please select an address from Google Maps");
      }
    }
    fetchAddress();
    setAllotedTimeSlot(updatedValues)
    alert("Updated Default Address");
    // fetchAddress()
  };

  const [myAccountID, setMyAccountID] = useState();

  const [EditData, setEditData] = useState();

  const editaddress = async (id) => {
    var loginDataString = localStorage.getItem("accountLogin"); // This i~s a string
    var loginData = JSON.parse(loginDataString);

    var getaddress = await AddAddress.getAddressById(id, loginData.d1Id);
    console.log(getaddress);
    setEditData(getaddress);
    setAddressModal(true);
  };

  const deleteaddress = async (id) => {
    if (window.confirm("Are you sure you want to delete this address")) {
      setLoading(true);
      var loginDataString = localStorage.getItem("accountLogin"); // This i~s a string
      var loginData = JSON.parse(loginDataString);

      var deletedata = await AddAddress.deleteAddress(id, loginData.d1Id);
      if (deletedata) {
        alert("Address Deleted");
        fetchAddress();
        setLoading(false);
      } else {
        setLoading(false);
        alert("error Occoured");
      }
    }
  };

  const [myAccountdata, setMyAccountdata] = useState([]);

  useEffect(() => {
    // Retrieve data from local storage when the component mounts
    const storedData = localStorage.getItem('accountLogin');

    if (storedData) {
      // If data is found, update the state
      setMyAccountdata(storedData);
    }
  }, []);

  const [serviceType, setServiceType] = useState([]);

  useEffect(() => {
    // setLoading(true);
    const fetchdata = async () => {
      const data = await Order.getServiceType();
      if (data) {
        setServiceType(data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
      // setLoading(false);
    };
    fetchdata();
  }, []);

  const fetchAddress = async () => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    if (loginData) {
      // console.log(loginData.d1Id);
      setMyAccountID(loginData.d1Id)
      var addresses = await AddAddress.getAddress(loginData.d1Id);
      if (addresses) {
        setAddAddresses(addresses);

        // ********************* Set Time Slot **********************
        const defaultAdd = addresses.filter(item => item.isDefault == true);
        //alert(defaultAdd[0].distance)

        const serviceType = localStorage.getItem("ServiceType");
        const updatedValues = {
          orderType: serviceType || 751,
          deliveryPointName: 1,
          distance: defaultAdd[0].distance,
          isCurrentDate: true
        };

        // if (defaultAdd[0].distance == 0 || defaultAdd[0].distance == null || defaultAdd[0].distance == undefined) {
        //   alert("The distance is 0. Please select an address from Google Maps");
        // }

        const data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues, CompanyDetail.isEnableGoogleMap);

        const serviceTypeId = updatedValues.orderType;
        const serviceData = serviceType.filter(item => item.id == serviceTypeId)
        const selectedTimeSlot = data.slice(0, serviceData[0]?.noOfTimeSlot);

        setAllottedTimeSlot(selectedTimeSlot);
        // setAddressModal(false);
        setLoading(false);
      } else {
        alert("Error Occoured");
        setLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   const fetchAddress = async () => {
  //     var loginDataString = localStorage.getItem("accountLogin");
  //     var loginData = JSON.parse(loginDataString);
  //     if (loginData) {
  //       // console.log(loginData.d1Id);
  //       setMyAccountID(loginData.d1Id)
  //       var addresses = await AddAddress.getAddress(loginData.d1Id);
  //       if (addresses) {
  //         setAddAddresses(addresses);
  //         // debugger
  //         setAddressModal(false);
  //         setLoading(false);
  //       } else {
  //         alert("Error Occoured");
  //         setLoading(false);
  //       }
  //     }
  //     setLoading(false);
  //   };

  //   fetchAddress();
  // }, []);

  // console.log(addresses);

  const [addressModal, setAddressModal] = useState(false);
  const openAddressmodal = () => {
    openmainmodal()
    // setAddressModal(!addressModal);
    // setEditData("")
  };
  const closeAddressModal = () => {
    setAddressModal(false)
  }

  return (
    <div>
      <div>
        <div>
          <button className="add-address-button" onClick={openAddressmodal}>
            Add Address <i className="fa-solid fa-plus"></i>
          </button>

          {addresses && addresses.length > 0 ? (
            addresses.map((address, index) => (
              <div key={index} className="address-box"  >
                <div onClick={() => handleCheckboxChange(index, address.id)} style={{ width: "100%" }}>
                  <label className="custom-checkbox address-logo">
                    <input
                      type="checkbox"
                      name="addressCheckbox"
                      value={address.isDefault}
                      checked={address.isDefault}
                      disabled
                    // onChange={() => handleCheckboxChange(index, address.id)}
                    />
                    <span className="checkmark"></span>
                  </label>

                  <p className="address-text">
                    {address.typeOfAddress === 0 ? <i class="fa-solid fa-house address-icon-1"></i> : <i class="fa-solid fa-building-user address-icon-1"> </i>}

                    {address.name}</p>
                  <p className="address-text" style={{ fontWeight: "100" }}>
                    {address.address} --- {address.distance ? address.distance : 0} km</p>
                </div>
                <div className="adress-delete-edit">
                  <i
                    onClick={() => editaddress(address.id)}
                    style={{ color: "#305197" }}
                    className="fa-regular fa-pen-to-square fs-b"
                  ></i>
                  <i
                    onClick={() => deleteaddress(address.id)}
                    style={{ color: "#e02121" }}
                    className="fa-solid fa-trash fs-b "
                  ></i>
                </div>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>

      {addressModal && (
        <AddAddressModalProfle
          data={EditData}
          modelOpen={true}
          // fetchAddress={fetchAddress}
          modalclose={closeAddressModal}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default MyAccountComponent2Address;
